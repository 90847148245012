import {
  Typography,
  Button,
  Box,
  List,
  ListItem,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import GenericAuthenticator from '../common/GenericAuthenticator';
import { routes } from '../../utils/routeConfig';

interface Props {
  toggleVisibility: (newOpen: boolean) => () => void;
}


export const NavBarNavigation = ({ toggleVisibility }: Props) => {
  const location = useLocation().pathname;

  return (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleVisibility(false)}>
      <List>
        <ListItem>
          <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
            <Button
              variant={location === '/teams' ? 'contained' : 'outlined'}
              component={Link}
              to='/teams'
            >
              Team Resources
            </Button>
          </Typography>
        </ListItem>
        {routes.map((route) => {
          return (
            route.isEnabled &&
            <GenericAuthenticator teams={route.teams} games={route.games} roles={route.roles}>
              <ListItem>
                <Typography component='h1' variant='h6' sx={{ flexGrow: 1 }}>
                  <Button
                    variant={
                      location === route.path ? 'contained' : 'outlined'
                    }
                    component={Link}
                    to={route.path}
                  >
                    {route.name}
                  </Button>
                </Typography>
              </ListItem>
            </GenericAuthenticator>
          )
        })}
      </List >
    </Box>
  )
}