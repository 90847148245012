import { DateTimePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { SetStateAction, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { flatten, uniq, isEmpty } from "lodash";
import {
  Box, Button, Card,
  CardContent, Container, FormControl,
  Grid, InputLabel, MenuItem,
  Paper, Select, SelectChangeEvent,
  TextField, Typography
} from "@mui/material";

import {
  ANOAccounts, ANORoster,
  GSMCAccounts, GSMCRoster,
  HELLAccounts, HELLRoster,
  RFSAccounts, RFSRoster,
  TPAccounts, TPRoster,
  TPXAccounts, TPXRoster,
  WLGAccounts, WLGRoster,
  WPEAccounts, WPERoster
} from "../../../utils/data/gllSummer2024Teams"
import {
  B2TGAccounts, B2TGRoster,
  EIQAccounts, EIQRoster,
  FSKAccounts, FSKRoster,
  GLRAccounts, GLRRoster,
  UNITAccounts, UNITRoster,
  Z10Accounts, Z10Roster
} from "../../../utils/data/eplSeason2Teams";

import ChampionImage from "../../../components/Images/ChampionImage";
import { getMatchesbyPuuids } from "../../../services/api/atlas";
import GenericAuthenticator from "../../../components/common/GenericAuthenticator";
import { getAccountByPuuid, getAccountByRiotId, getMatchList, ingestMatchToServerless } from "../../../services/api/riot";
import { LoadingContext } from "../../../context/LoadingContext";

import { Champions } from "../../../types/processing";
import { Match } from "../../../types/api/riot/match";
import { Queues } from "../../../types/riot";

import { getChampionIdFromName } from "../../../utils/riot";
import { getPatchDatesFromVersion } from "../../../utils/patch";
import QueuesJSON from "../../../utils/data/riot/queues.json"
import { sortChamps } from "../../../utils/stats";
import { EINSAccounts, EINSRoster } from "../../../utils/data/emSummer2024";

export const RiotScouting = () => {
  const [patch, setPatch] = useState<string>('14.14');
  const [team, setTeam] = useState<string>('');
  const [currentRoster, setCurrentRoster] = useState<string[]>([]);
  const [player, setPlayer] = useState<string>('');
  const [playerData, setPlayerData] = useState<Accounts[]>([]);
  const [currStats, setCurrStats] = useState<any[]>([]);
  const [summonerName, setSummonerName] = useState<string>('')
  const [summonerNameInput, setSummonerNameInput] = useState<string>('')
  const [puuid, setPuuid] = useState<string>('')
  const [puuidInput, setPuuidInput] = useState<string>('')
  const [startingMatchInput, setStartingMatchInput] = useState<number>(0)
  const [matchesInput, setMatchesInput] = useState<number>(50)
  const [queue, setQueue] = useState<number>(0)
  const [startTime, setStartTime] = useState<number>(dayjs().unix())
  const [endTime, setEndTime] = useState<number>(dayjs().unix())

  const { setIsLoading } = useContext(LoadingContext);

  const patches = ["14.8", "14.9", "14.10", "14.11", "14.12", "14.13", "14.14", "14.15"]
  const updateDate = 1722427081
  const queues = QueuesJSON as Queues
  const filteredQueues = queues.filter((queue) => {
    return (
      queue.map.includes('Custom games') ||
      (
        !(queue.notes && queue.notes.toLowerCase().includes('deprecated'))
        && (!queue.notes && queue.description?.toLowerCase().includes('5v5'))
        && (!queue.notes && queue.map.toLowerCase().includes("summoner's rift"))
      )
    )
  })

  const procMatchList = (matchList: Match[], puuids: string[]) => {
    const flattenMap = flatten(matchList.map((match) => {
      return match.info.participants.filter((participant) => {
        return puuids.some((puuid) => puuid === participant.puuid)
      })
    }))

    const championNames = uniq(flattenMap.map((match) => {
      return match.championName
    }))

    const championStats = championNames.map((champion) => {
      const championGames = flattenMap.filter((match) => {
        return match.championName === champion
      })
      const championStats = {
        [champion]: {
          kills: 0,
          deaths: 0,
          assists: 0,
          games: 0,
          wins: 0
        }
      }
      // eslint-disable-next-line array-callback-return
      championGames.map((match) => {
        championStats[champion].kills += match.kills
        championStats[champion].deaths += match.deaths
        championStats[champion].assists += match.assists
        championStats[champion].games += 1
        if (match.win) {
          championStats[champion].wins += 1
        }
      })
      return championStats
    })
    return championStats
  }

  type GLLTeams = 'WLG' | 'ANO' | 'TP' | 'TPX' | 'HELL' | 'WPE' | 'RFS' | 'GSMC'
  type EPLTeams = 'B2TG' | 'FSK' | 'EIQ' | 'GLR' | 'UNIT' | 'Z10'
  type EMTeams = 'EINS'

  type Teams = GLLTeams | EPLTeams | EMTeams

  const teamList: Teams[] = [
    'WLG',
    'ANO',
    'TP',
    'TPX',
    'HELL',
    'WPE',
    'RFS',
    'GSMC',
    'B2TG',
    'FSK',
    'EIQ',
    'GLR',
    'UNIT',
    'Z10',
    'EINS'
  ]

  type RosterList = {
    [x in Teams]: string[];
  };

  const rosterList: RosterList = {
    'ANO': ANORoster,
    'B2TG': B2TGRoster,
    'EINS': EINSRoster,
    'EIQ': EIQRoster,
    'FSK': FSKRoster,
    'GLR': GLRRoster,
    'GSMC': GSMCRoster,
    'HELL': HELLRoster,
    'RFS': RFSRoster,
    'TP': TPRoster,
    'TPX': TPXRoster,
    'UNIT': UNITRoster,
    'WLG': WLGRoster,
    'WPE': WPERoster,
    'Z10': Z10Roster
  }

  interface Accounts {
    puuid: string;
    gameName?: string;
    tagLine?: string;
  }

  interface TeamAccounts {
    [x: string]: Accounts[]
  }

  type AccountList = {
    [x in Teams]: TeamAccounts;
  };

  const accountList: AccountList = {
    'ANO': ANOAccounts,
    'B2TG': B2TGAccounts,
    'EINS': EINSAccounts,
    'EIQ': EIQAccounts,
    'FSK': FSKAccounts,
    'GLR': GLRAccounts,
    'GSMC': GSMCAccounts,
    'HELL': HELLAccounts,
    'RFS': RFSAccounts,
    'TP': TPAccounts,
    'TPX': TPXAccounts,
    'UNIT': UNITAccounts,
    'WLG': WLGAccounts,
    'WPE': WPEAccounts,
    'Z10': Z10Accounts
  }

  const handleTeamChange = (event: SelectChangeEvent) => {
    setTeam(event.target.value)
    setPlayer('')
    setPlayerData([])
    setCurrStats([])
  }

  useEffect(() => {
    setCurrentRoster(rosterList[team as keyof RosterList])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team])

  const handlePlayerChange = (event: SelectChangeEvent) => {
    const playerData: Accounts[] = accountList[team as keyof AccountList][event.target.value as keyof TeamAccounts]
    setPlayer(event.target.value)
    setPlayerData(playerData)
    setCurrStats([])
  }

  const handlePatchChange = (event: SelectChangeEvent) => {
    setPatch(event.target.value)
    setCurrStats([])
  }

  const handleQueueChange = (event: SelectChangeEvent) => {
    setQueue(Number(event.target.value))
  }

  useEffect(() => {
    const [startDate, endDate] = getPatchDatesFromVersion(patch)
    if (!isEmpty(playerData)) {
      setIsLoading(true)
      getMatchesbyPuuids(playerData.map((account) => account.puuid).toString(), (startDate * 1000), (endDate * 1000))
        .then((matches) => {
          setCurrStats(
            procMatchList(matches, playerData.map((account) => account.puuid))
          )
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerData, patch])

  const Stats = (currStats: { currStats: Champions[] }) => {

    return (
      <>
        {!isEmpty(currStats) && currStats && currStats['currStats'].sort(sortChamps).map((champion) => {
          return Object.entries(champion).map(([championName, stats]) => {
            return (
              <>
                <Grid item xs={3}>
                  <Box key={championName}>
                    <ChampionImage
                      championId={getChampionIdFromName(championName)}
                    />
                    <Typography>
                      {championName}
                    </Typography>
                    <Typography>
                      {`${(stats.kills / stats.games).toFixed(1)}/${(stats.deaths / stats.games).toFixed(1)}/${(stats.assists / stats.games).toFixed(1)} (${((stats.kills + stats.assists) / stats.deaths).toFixed(2)} KDA)`}
                    </Typography>
                    <Typography>
                      {`${stats.games} games /${stats.wins} wins (${Math.floor((stats.wins * 100) / stats.games)}% w/r)`}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )
          })
        })}
      </>
    )
  }

  const handleSummonerNameInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setSummonerNameInput(event.target.value)
  }

  const handlePUUIDInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setPuuidInput(event.target.value)
  }

  const handleStartingMatchInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setStartingMatchInput(Number(event.target.value as string))
  }

  const handleMatchesInputChange = (event: { target: { value: SetStateAction<string>; }; }) => {
    setMatchesInput(Number(event.target.value as string))
  }

  const handleSummonerNameLookup = () => {
    setSummonerNameInput('')
    getAccountByPuuid(puuidInput)
      .then((summoner) => {
        setSummonerName(`${summoner.gameName}#${summoner.tagLine}`)
        setPuuid(summoner.puuid)
      })
  }

  const handlePUUIDLookup = () => {
    setPuuidInput('')
    const [gameName, tagLine] = summonerNameInput.split('#')
    getAccountByRiotId(gameName, tagLine)
      .then((summoner) => {
        setSummonerName(`${summoner.gameName}#${summoner.tagLine}`)
        setPuuid(summoner.puuid)
      })
  }

  const handleLookupIngestion = () => {
    if (puuid) {
      getMatchList(puuid, matchesInput, startingMatchInput, `${queue}`, startTime)
        .then((matchList) => {
          // eslint-disable-next-line array-callback-return
          matchList.map((matchId) => {
            ingestMatchToServerless(matchId)
          })
        })
    }
  }

  const ingestionHandler = (puuids: string[]) => {
    let mergedMatches: string[] = []

    // eslint-disable-next-line array-callback-return
    const matchesPromiseArray = puuids.map((puuid, index) => {
      return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          getMatchList(puuid, matchesInput, startingMatchInput, `${queue}`, startTime)
            .then((matchList) => {
              mergedMatches.push(...matchList)
              return resolve()
            })
            .catch(() => {
              return reject()
            })
        }, (index * 250))
      })
    })

    Promise.all(matchesPromiseArray)
      .then(() => {
        toast.info(
          `${mergedMatches.length} matches`,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
          }
        )
        // eslint-disable-next-line array-callback-return
        uniq(mergedMatches).map((matchId, index) => {
          setTimeout(() => {
            ingestMatchToServerless(matchId)
          }, (index * 800))
        })
      })
      .finally(() => {
        mergedMatches = []
      })
  }

  const handlePlayerIngestion = () => {
    let mergedMatches: string[] = []
    const matchesPromiseArray = playerData.map((account) => {
      return new Promise<void>((resolve, reject) => {
        getMatchList(account.puuid, matchesInput, startingMatchInput, `${queue}`, startTime)
          .then((matchList) => {
            mergedMatches.push(...matchList)
            return resolve()
          })
          .catch(() => {
            return reject()
          })
      })
    })

    Promise.all(matchesPromiseArray)
      .then(() => {
        toast.info(
          `${mergedMatches.length} matches`,
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
          }
        )
        // eslint-disable-next-line array-callback-return
        mergedMatches.map((matchId, index) => {
          setTimeout(() => {
            ingestMatchToServerless(matchId)
          }, (index * 500))
        })
      })
      .finally(() => {
        mergedMatches = []
      })
  }

  const handleMassIngestion = () => {
    const flatPuuids = Object.values(accountList).map((team) => {
      return Object.values(team)
    }).flat().flat().map((account) => {
      return account.puuid
    })
    toast.info(
      `${flatPuuids.length} puuids`,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
      }
    )
    ingestionHandler(flatPuuids)
  }

  const handleTeamIngestion = () => {
    const flatPuuids = Object.values(accountList[team as keyof AccountList])
      .map((playerData) => {
        return playerData.map((account) => {
          return account.puuid
        })
      }).flat()
    toast.info(
      `${flatPuuids.length} puuids`,
      {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "dark",
      }
    )
    ingestionHandler(flatPuuids)
  }

  return (
    <Container>
      <Paper>
        <Card>
          <CardContent>
            <GenericAuthenticator roles={['admin']}>
              <Box>
                <Typography variant="h6">
                  Summoner to PUUID
                </Typography>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    label="Summoner Name"
                    value={summonerNameInput}
                    onChange={handleSummonerNameInputChange}
                  />
                </FormControl>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    label="PUUID"
                    value={puuid}
                    onClick={() => navigator.clipboard.writeText(puuid)}
                  />
                </FormControl>
                <Button
                  sx={{ marginTop: '16px', minWidth: '160px' }}
                  onClick={handlePUUIDLookup}
                  variant='outlined'
                >
                  PUUID Lookup
                </Button>
              </Box>
              <Box>
                <Typography variant="h6">
                  PUUID to Summoner Name
                </Typography>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    label="PUUID"
                    value={puuidInput}
                    onChange={handlePUUIDInputChange}
                  />
                </FormControl>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    label="Summoner Name"
                    value={summonerName}
                    onClick={() => navigator.clipboard.writeText(summonerName)}
                  />
                </FormControl>
                <Button
                  sx={{ marginTop: '16px', minWidth: '160px' }}
                  onClick={handleSummonerNameLookup}
                  variant='outlined'
                >
                  SN Lookup
                </Button>
              </Box>
              <Box>
                <Typography variant="h6">
                  Ingestion Config
                </Typography>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    onChange={handleStartingMatchInputChange}
                    value={startingMatchInput}
                    label="Starting Match"
                    type="number"
                  />
                </FormControl>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <TextField
                    onChange={handleMatchesInputChange}
                    value={matchesInput}
                    label="Matches"
                    type="number"
                    defaultValue={50}
                  />
                </FormControl>
                <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                  <InputLabel>Queue</InputLabel>
                  <Select
                    label="Queue"
                    onChange={handleQueueChange}
                    placeholder="Select Queue"
                    fullWidth
                  >
                    {filteredQueues.map((queue) => {
                      return (
                        <MenuItem
                          key={queue.queueId}
                          value={queue.queueId}
                        >
                          {`${queue.description} - ${queue.map}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <DateTimePicker
                  label="Start Time"
                  sx={{ marginTop: '16px', minWidth: '160px' }}
                  value={dayjs.unix(startTime)}
                  onChange={(newValue) => setStartTime(newValue!.unix())}
                />
                <DateTimePicker
                  label="End Time"
                  sx={{ marginTop: '16px', minWidth: '160px' }}
                  value={dayjs.unix(endTime)}
                  onChange={(newValue) => setEndTime(newValue!.unix())}
                />
                <Button
                  sx={{ marginTop: '16px', minWidth: '160px' }}
                  onClick={handleLookupIngestion}
                  variant='outlined'
                >
                  Ingest Current Lookup
                </Button>
              </Box>
            </GenericAuthenticator>
            <Box>
              <Typography variant="h6">
                {`Riot Match Scouting (Updated on ${dayjs.unix(updateDate).format('MMMM D h:mm A')})`}
              </Typography>
              <Box>
                <GenericAuthenticator roles={['admin']}>
                  <Button
                    sx={{ marginTop: '16px', minWidth: '160px' }}
                    onClick={handleMassIngestion}
                    variant='outlined'
                  >
                    Mass Ingestion
                  </Button>
                </GenericAuthenticator>
              </Box>
              <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                <InputLabel>Team</InputLabel>
                <Select
                  value={team}
                  label="Team"
                  onChange={handleTeamChange}
                  placeholder="Select Team"
                  fullWidth
                >
                  {teamList.map((team) => {
                    return <MenuItem key={team} value={team}>{team}</MenuItem>
                  })}
                </Select>
              </FormControl>
              {currentRoster && (
                <>
                  <FormControl sx={{ marginTop: '16px', minWidth: '160px' }}>
                    <InputLabel>Player</InputLabel>
                    <Select
                      value={player}
                      label="Player"
                      onChange={handlePlayerChange}
                      fullWidth
                    >
                      {currentRoster.map((player) => {
                        return <MenuItem key={player} value={player}>{player}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ marginTop: '16px', minWidth: '160px' }} >
                    <InputLabel>Patch</InputLabel>
                    <Select
                      value={patch}
                      label="Patch"
                      onChange={handlePatchChange}
                      fullWidth
                    >
                      {patches.map((patch) => {
                        return <MenuItem key={patch} value={patch}>{patch}</MenuItem>
                      })}
                    </Select>
                  </FormControl >
                </>
              )}
              <GenericAuthenticator roles={['admin']}>
                <Box>
                  {currentRoster && (
                    <Button
                      sx={{ marginTop: '16px', minWidth: '160px' }}
                      onClick={handleTeamIngestion}
                      variant='outlined'
                    >
                      Team Ingestion
                    </Button>
                  )}
                  {player && (
                    <Button
                      sx={{ marginTop: '16px', minWidth: '160px' }}
                      onClick={handlePlayerIngestion}
                      variant='outlined'
                    >
                      Ingest Player By Ingestion Config
                    </Button>
                  )}
                </Box>
              </GenericAuthenticator>
            </Box>
          </CardContent>
          <CardContent>
            <Grid container>
              {(!isEmpty(currStats) && currStats)
                ? <Stats currStats={currStats}></Stats>
                : <></>
              }
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  )
}
