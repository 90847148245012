import { isEmpty } from "lodash"
import { useContext, useEffect, useState } from "react"
import { Box, Container, Paper } from "@mui/material"

import { getTNIScrims } from "../../../services/api/atlas"
import { GAMHSummaryList } from "../../../types/api/atlas/GAMH_Summary"
import CollapsableCard from "../../../components/common/CollapsableCard"
import { GAMHMatchDetails } from "../../../components/GAMHMatchDetails"
import { LoadingContext } from "../../../context/LoadingContext"

export const TNIScrimsDashboard = () => {
  const timeSelections = {
    hitpointWinter2025: {
      split: 'Hitpoint Winter 2025',
      startTime: 1735693261000,
      endTime: 1743469261000
    }
  }

  const [scrimList, setScrimList] = useState<GAMHSummaryList>([])
  const [startTime] = useState<number>(timeSelections.hitpointWinter2025.startTime)
  const [endTime] = useState<number>(timeSelections.hitpointWinter2025.endTime)

  const { setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getTNIScrims(startTime, endTime).then((data) => {
      setIsLoading(false)
      setScrimList(data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime])

  return (
    <Container>
      <Paper>
        {!isEmpty(scrimList) && (
          <CollapsableCard
            defaultExpanded={false}
            keyword="Scrims"
            title={'Scrim List'}
          >
            <>
              {scrimList.map((scrim, i) => {
                const ownTeamId = scrim.participants.find((participant) => {
                  return participant.riotIdGameName.includes('TNI')
                })?.teamId
                const isWin = scrim.teams.find((team) => team.teamId === ownTeamId)?.win
                const copyGameId = () => {
                  navigator.clipboard.writeText(`${scrim.platformId}_${scrim.gameId}`)
                }
                return (
                  <CollapsableCard
                    defaultExpanded={false}
                    keyword="Scrim"
                    title={
                      (
                        <Box onClick={copyGameId}>{`${new Date(
                          scrim.gameStartTimestamp
                        ).toLocaleDateString()} - ${scrim.gameVersion.slice(0, 5)} - ${scrim.gameName}`}
                        </Box>
                      )
                    }
                    key={i}
                    color={isWin ? '#202a3e' : '#472a2f'}
                  >
                    <GAMHMatchDetails match={scrim} />
                  </CollapsableCard>
                );
              })}
            </>
          </CollapsableCard>
        )
        }
      </Paper>
    </Container >
  )
} 