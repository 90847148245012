import { useContext } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { GuardedRoutes } from './components/common/GuardedRoutes';
import { NavBar } from './components/NavBar/NavBar';
import { LoadingContext } from './context/LoadingContext';

import { Home } from './pages/Main/Home';
import { TeamResources } from './pages/Dev/TeamResources';

import { routes } from './utils/routeConfig';

export const AppRoutes = () => {
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      <NavBar />
      <Container maxWidth="xl">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/teams' element={<TeamResources />} />
          <Route element={<GuardedRoutes />}>
            {routes.map((route) => {
              return <Route path={route.path} element={route.component} />
            })}
          </Route>
        </Routes>
      </Container>
      {isLoading &&
        <Box sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 10000,
          margin: '36px'
        }}>
          <CircularProgress />
        </Box>
      }
      <ToastContainer />
    </>
  );
};
