import {find} from 'lodash';
import champions from './data/riot/champion.json';
import summoner from './data/riot/summoner.json';

export const getChampionIdFromName = (championName: string) => {
  const matchingChampion = find(champions.data, (entry) => {
    return entry.id === championName;
  });
  return Number(matchingChampion?.key) || 1;
};

export const getChampionNameFromId = (championId: string) => {
  const matchingChampion = find(champions.data, (entry) => {
    return entry.key === championId;
  });
  return matchingChampion?.name || 'Missing Champion';
};

export const getSummonerNameFromId = (summonerId: number) => {
  const matchingSummoner = find(summoner.data, (entry) => {
    return entry.key === summonerId.toString();
  });
  return matchingSummoner?.id || 'SummonerPoroThrow';
};
