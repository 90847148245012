import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  Container,
  Paper,
  Button,
  Card,
  Dialog,
  DialogTitle,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';


import './index.css';

import { ParsedGamesList } from '../../../types/api/bayes/emhList';
import { getDownload, getEsports, postDownload } from '../../../services/api/grid';
import { SeriesFile, SeriesNode } from '../../../types/api/grid/grid';
import GenericAuthenticator from '../../../components/common/GenericAuthenticator';
import { LoadingContext } from '../../../context/LoadingContext';

export const EsportsMatches = () => {
  const [downloadModalVisible, setDownloadModalVisible] = useState(false)
  const [downloadList, setDownloadList] = useState<SeriesFile[]>()
  const [emhList, setEmhList] = useState<ParsedGamesList>();
  const [previousCursors, setPreviousCursors] = useState({
    endCursor: '',
    startCursor: ''
  })
  const [cursors, setCursors] = useState({
    endCursor: '',
    startCursor: ''
  })

  const { isLoading, setIsLoading } = useContext(LoadingContext);

  useEffect(() => {
    setIsLoading(true)
    getEsports(cursors, "GSMC").then((data) => {
      const endCursor = data.pageInfo.hasNextPage ? data.pageInfo.endCursor : '';
      const startCursor = data.pageInfo.hasNextPage ? data.pageInfo.startCursor : '';
      setPreviousCursors({ endCursor, startCursor })
      setEmhList(parseEsportsMatches(data.edges));
      setIsLoading(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursors]);

  const parseEsportsMatches = (nodes: SeriesNode[]): ParsedGamesList => {
    const parsedGames = nodes.map((node, i) => {
      const game = node.node
      return {
        id: i + 1,
        tournament: game.tournament ? game.tournament.name : '',
        date: new Date(game.startTimeScheduled).getTime() || 0,
        team1: !isEmpty(game.teams) ? game.teams[0].baseInfo.name : '',
        team1Logo: !isEmpty(game.teams) ? game.teams[0].baseInfo.logoUrl : '',
        team2: !isEmpty(game.teams) ? game.teams[1].baseInfo.name : '',
        team2Logo: !isEmpty(game.teams) ? game.teams[1].baseInfo.logoUrl : '',
        gameId: game.id || '',
      };
    });
    return parsedGames;
  };

  const handleDownloadModal = (gameId: string) => {
    setIsLoading(true)
    getDownload(gameId, "GSMC").then((data) => {
      setDownloadList(data.files)
      setDownloadModalVisible(true)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleDownloadButton = (url: string) => {
    setIsLoading(true)
    postDownload(url, "GSMC")
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getDataRows: GridRowsProp = (emhList as GridRowsProp) || [];

  const downloadButton = (gameId: string) => {
    return (
      <Button variant={'outlined'} onClick={() => handleDownloadModal(gameId)}>
        Download
      </Button>
    );
  };

  const teamName = (teamName: string, logoUrl: string) => {
    return (
      <>
        <img src={logoUrl} alt='team logo' height={"24px"} width={"24px"} />
        <Typography sx={{ marginLeft: '8px' }}>
          {teamName}
        </Typography>
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return format(new Date(params.value), 'dd MMM, H:mm');
      },
    },
    {
      field: 'tournament',
      headerName: 'Tournament',
      width: 200,
      sortable: false,
    },
    {
      field: 'team1',
      headerName: 'Team 1',
      description: '',
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return teamName(params.value, params.row.team1Logo);
      },
    },
    {
      field: 'team2',
      headerName: 'Team 2',
      description: '',
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return teamName(params.value, params.row.team2Logo);
      },
    },
    {
      field: 'gameId',
      headerName: 'Replay',
      description: '',
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return downloadButton(params.value);
      },
    },
  ];

  const handlePages = (direction: string) => {
    direction === 'prev' && (
      setCursors({ startCursor: '', endCursor: previousCursors.startCursor })
    )
    direction === 'next' && (
      setCursors({ startCursor: previousCursors.endCursor, endCursor: '' })
    )
  }

  return (
    <Container>
      <Paper>
        <Card className={'cardSpacing'}>
          {emhList && (
            <>
              <DataGrid
                rows={getDataRows}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10]}
                autoHeight
              />
              <Button variant='outlined' disabled={previousCursors.startCursor === ''} onClick={() => handlePages('prev')}>Prev 50</Button>
              <Button variant='outlined' disabled={previousCursors.endCursor === ''} onClick={() => handlePages('next')}>Next 50</Button>
            </>
          )}
          <Dialog
            onClose={() => setDownloadModalVisible(false)}
            open={downloadModalVisible}
          >
            <DialogTitle>
              {(downloadList && !isEmpty(downloadList.filter((file) => {
                return file.status === 'ready'
              }))
                ? "Downloads"
                : "No downloads available"
              )}
            </DialogTitle>
            <List sx={{ pt: 0 }}>
              {downloadList && downloadList.map((file) => {
                if (file.status === 'ready') {
                  return (
                    <GenericAuthenticator
                      roles={
                        file.description.includes("Replay")
                          ? ['player', 'staff']
                          : file.description.includes("LiveStats")
                            ? ['staff']
                            : ['']
                      }
                    >
                      <ListItem key={file.id}>
                        <ListItemButton onClick={() => handleDownloadButton(file.fullURL)} disabled={isLoading}>
                          <ListItemText primary={file.description} />
                        </ListItemButton>
                      </ListItem>
                    </GenericAuthenticator>
                  )
                }
                return <></>
              })}
            </List>
          </Dialog >
        </Card>
      </Paper>
    </Container>
  );
};
